/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --checkout-form-layout-gap: 3rem;
  --checkout-heading-font-size: 1.6rem;
  --checkout-heading-color: #000;
  --checkout-heading-font-weight: 700;
  --custom-mark-color: #00A43C;
  --checkout-delivery-option-check-mark-color: var(--custom-mark-color);
  --checkout-payment-check-mark-color: var(--custom-mark-color);
  --checkout-payment-check-mark-hover-color: var(--custom-mark-color);
  --checkout-address-table-check-mark-hover-color: var(--custom-mark-color);
  --checkout-totals-background-color: #F9F9F9;
  --checkout-totals-border: 1px solid var(--input-border-color);
  --checkout-totals-padding: 2rem;
  --checkout-totals-custom-button-border-radius: 0.5rem;
  --checkout-custom-button-wrapper-padding: 0 2rem 2rem;
  
  @include mobile {
    --checkout-form-layout-gap: 2rem;
  }
}

.Checkout {
    @include mobile {
      padding: 2rem 0;
    }

    @include desktop {
      margin-top: 0;
    }

    &-StickyButtonWrapper {
      @include mobile {
          bottom: 0;
          left: 0;
      }
    }

    &:before {
      display: none;
    }

    &-Wrapper {
      gap: var(--checkout-form-layout-gap);

      @include desktop {
        grid-template-columns: 1fr 1fr;

        &_isShipping {
          max-width: var(--content-wrapper-width);
          grid-template-columns: 1.2fr 1fr .8fr;
          grid-template-areas:
            "head head head" 
            "col1 col2 col3"
            "col1b col2 col3";
          grid-gap: var(--checkout-form-layout-gap);

          .Checkout-OrderSummaryWrapper {
            @include desktop {
              grid-area: col3;
              margin-top: 0;
            }
          }
        }
      }
    }

    &-ProgressSection {
      @include mobile {
          display: block;
      }
    }

    &-Title {
      width: 100%;
      font-size: 3rem;

      @include mobile {
        display: block;
        margin-top: 0;
        font-size: 2.4rem;
        margin-bottom: 0;
      }

      @include desktop {
        grid-area: head;
        margin: 2rem 0 0 0;
      }
    }

    &-Step {
      flex: 1;

      @include desktop {
        &_isShipping {
          display: contents;
        }
      }
    }

    .CheckoutAddressBook {
      margin-top: 0;
      @include desktop {
        grid-area: col1b;

        &_isSignedIn {
          grid-area: col1;
        }
      }
    }

    &-OrderSummaryWrapper {
      max-width: 45rem;
      width: 100%;
      margin-top: 2.4rem;
    }

    .CartItem-Wrapper {
      grid-template-columns: 1fr;
    }

    .CartItem-Picture {
      display: none;
    }

    &-Heading,
    .CheckoutOrderSummary-Header {
      font-size: var(--checkout-heading-font-size);
      color: var(--checkout-heading-color);
      font-weight: var(--checkout-heading-font-weight);
      padding-bottom: 2rem;
      border-bottom: 1px solid var(--input-border-color);
      margin-bottom: 0;
    }

    .Field {

      @include mobile {
        flex-direction: column;
      }

      input,
      select,
      textarea,
      .FieldSelect {

        @include mobile {
          width: 100%;
        }
      }

      select {
        padding: var(--input-padding);
      }

      &-Message {
        margin-left: auto;

        @include mobile {
          margin-left: 0;
        }
      }
    }

    .CheckoutGuestForm {
      @include desktop {
        grid-area: col1;
      }

      width: 100%;
      margin-top: 0;

      .Field_type_checkbox {
        label {
          align-items: center;
        }
      }
    }

    .CustomerNotesTextArea {
      margin-bottom: 3rem;
      @include desktop {
        grid-column-start: 2;
        grid-row-start: 3;
      }
    }

    .CheckoutDeliveryOption {
      border: 1px solid var(--input-border-color);
      margin-bottom: 1rem;

      &-PickupStore {
        select {
          padding: 1.3rem 3.8rem 1.3rem 2rem;
        }
      }

      &-Row {
        margin-left: 1rem;

        br {
          display: none;
        }

        > strong {
          color: #000;
          font-weight: 400;
          margin-left: 1rem;
        }
      }

      &-Button {
        padding: 2.2rem 3.6rem 2.2rem;

        &:after {
          border-right: 2px solid var(--checkout-delivery-option-check-mark-color);
          border-top: 2px solid var(--checkout-delivery-option-check-mark-color);
        }
      }
    }

    .StoreLocatorSelect,
    .OrderDateSelect {
      @include mobile {
        margin-bottom: 4rem;
      }
    }

    .CheckoutOrderSummary {
      @include mobile {
        margin-bottom: 2rem;
        width: 100%;
        margin-top: 2rem;
      }

      &-ItemsInCart {
        margin-bottom: 0;
        line-height: inherit;
      }

      &-Header {
        margin-top: 0;
      }

      &-ExpandableContentButton {
        @include desktop {
          padding: 1.7rem 1.4rem;
        }

        &.ExpandableContent-Button_isContentExpanded {
          padding-bottom: 0;
        }
      }

      &-ExpandableContentContent {
        padding-top: 0;
      }

      &-OrderTotals {
        margin-top: 2.4rem;

        @include desktop {
          background-color: var(--checkout-totals-background-color);
          border: var(--checkout-totals-border);
          padding: var(--checkout-totals-padding);
        }
      }

      &-CustomCheckoutButtonWrapper {
        background-color: var(--checkout-totals-background-color);
        border: var(--checkout-totals-border);
        padding: var(--checkout-custom-button-wrapper-padding);
        border-top: 0;
      }

      &-SummaryItem {
        border-bottom: 1px solid var(--input-border-color);
      }
    }

    .CartItem {
      &-Wrapper {
        padding: 2rem 1rem 2rem 0;
      }

      &-HeadingWrapper {
        width: 100%;
      }

      &-Heading {
        margin-bottom: 0;
      }

      &-Options {
        width: 100%;
      }

      &-QuantityWrapper {
        font-size: 1.4rem;

        .CartItem-Qty {
          display: none;
        }

        @include desktop {
          margin-top: 1rem;
        }

        @include mobile {
          margin-bottom: 0;
        }
      }

      &-QuantityText {
        display: inline-block;
        margin-right: 0.4rem;
      }

      &-QuantityValue {
        font-weight: 700;
        display: inline-block;
      }

      &-Qty {
        width: 10rem;
      }

      &-Content {
        flex-direction: row;

        .CartItem-QuantityWrapper {
          display: flex;
        }
      }

      &-Price {
        align-self: center;

        @include mobile {
          > span {
            width: 100%;
            text-align: right;
          }
        }

        @include desktop {
          margin-top: 1rem;
        }
      }

      &-Actions {
        @include mobile {
          display: none;
        }
      }
    }

    .CheckoutPayment {
      border-top: none;
      padding: 0;

      &-Button {
        border: 1px solid var(--input-border-color);
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        margin-top: 1rem;
        min-height: var(--button-height);
        padding-left : var(--button-padding);;
        padding-right : var(--button-padding);;

        img {
          width: 5rem;
        }

        &_isSelected {
          background-color: var(--button-background);
          color: var(--button-color);
        }
      }
    }

    .Ideal-Form {
      border: none;
      padding: 0;
      min-height: unset;

      > div {
        display: flex;
        align-items: center;
      }

      .IdealSelect-Label {
        margin-right: 2rem;
      }

      .IdealSelect-Select {
        @include mobile {
          flex: 1;
          font-size: 1.6rem;
        }
      }
    }

    .CartCoupon {
      @include desktop {
        display: flex;
        align-items: center;
      }

      &-Input {
        margin-top: 0;
        flex: 1;
      }
    }
}